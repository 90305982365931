<template>
  <div>
    <a-alert
      message="欢迎使用立维站点扫描系统。"
      :show-icon="true"
      type="info"
      style="margin-bottom: 24px;"
    ></a-alert>
    <a-card :bordered="false">
      <a-card>
        <a-row :gutter="16">
          <a-col :md="6" :sm="24">
            <a-statistic title="域名" :value-style="valueStyle" style="user-select: none;">
              <router-link :to="{ name: 'DomainList' }" slot="formatter" style="color: rgba(0, 0, 0, 0.85);">{{ domain.total }}</router-link>
            </a-statistic>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-statistic title="子域名" :value="subdomain.total" :value-style="valueStyle" style="user-select: none;"></a-statistic>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-statistic title="站点" :value-style="valueStyle" style="user-select: none;">
              <router-link :to="{ name: 'SiteList' }" slot="formatter" style="color: rgba(0, 0, 0, 0.85);">{{ site.total }}</router-link>
            </a-statistic>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-statistic title="扫描" :value="siteScanTask.total" :value-style="valueStyle" style="user-select: none;"></a-statistic>
          </a-col>
        </a-row>
      </a-card>

      <a-row :gutter="16" style="margin-top: 24px;">
        <a-col :md="12" :sm="24">
          <a-card :bordered="false">
            <site-risk-severity-pie-chart></site-risk-severity-pie-chart>
          </a-card>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import {
  getDomainStatistic,
  getSiteRiskStatistic,
  getSiteScanTaskStatistic,
  getSiteStatistic,
  getSubdomainStatistic
} from '@/api/statistic'
import SiteRiskSeverityPieChart from './modules/SiteRiskSeverityPieChart'

export default {
  name: 'Dashboard',
  components: { SiteRiskSeverityPieChart },
  data () {
    return {
      valueStyle: { fontSize: '48px' },
      domain: {},
      subdomain: {},
      ipAddress: {},
      site: {},
      siteScanTask: {},
      siteRisk: {}
    }
  },
  mounted () {
    getDomainStatistic().then(res => { this.domain = res })
    getSubdomainStatistic().then(res => { this.subdomain = res })
    getSiteStatistic().then(res => { this.site = res })
    getSiteScanTaskStatistic().then(res => { this.siteScanTask = res })
    getSiteRiskStatistic().then(res => { this.siteRisk = res })
  }
}
</script>
