<template>
  <div id="site-risk-severity" style="height: 480px;"></div>
</template>

<script>
import * as echarts from 'echarts'
import { getSiteRiskStatistic } from '@/api/statistic'

export default {
  name: 'SiteRiskSeverityPieChart',
  mounted () {
    getSiteRiskStatistic().then(res => {
      const chart = echarts.init(document.getElementById('site-risk-severity'))
      chart.setOption({
        color: ['#FF5A60', '#FFC63A', '#7ECF69', '#ECECEC'],
        legend: { bottom: 0 },
        title: { text: '站点风险统计' },
        tooltip: { trigger: 'item' },
        series: [{
          name: '风险级别',
          type: 'pie',
          radius: '64%',
          data: [
            { name: '高危', value: res.high },
            { name: '中危', value: res.medium },
            { name: '低危', value: res.low },
            { name: '未知', value: res.unknown }
          ]
        }]
      })
      window.onresize = () => { chart.resize() }
    })
  }
}
</script>
