import request from '@/utils/request'

export function getSiteStatistic () {
  return request.get('/statistics/site')
}

export function getSiteRiskStatistic () {
  return request.get('/statistics/site-risk')
}

export function getSiteScanTaskStatistic () {
  return request.get('/statistics/site-scan-task')
}

export function getIpAddressStatistic () {
  return request.get('/statistics/ip-address')
}

export function getSslCertificateStatistic () {
  return request.get('/statistics/ssl-certificate')
}

export function getDomainStatistic () {
  return request.get('/statistics/domain')
}

export function getSubdomainStatistic () {
  return request.get('/statistics/subdomain')
}
